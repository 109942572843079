document.addEventListener('DOMContentLoaded', function(){
    const tabs = document.getElementsByClassName('tab');
    for(let i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener('click', tabSwitch);
    }

    // タブをクリックすると実行する関数
    function tabSwitch(){
        // タブのclassの値を変更
        document.getElementsByClassName('is-active')[0].classList.remove('is-active');
        this.classList.add('is-active');
        // コンテンツのclassの値を変更
        document.getElementsByClassName('is-show')[0].classList.remove('is-show');
        const arrayTabs = Array.prototype.slice.call(tabs);
        const index = arrayTabs.indexOf(this);
        document.getElementsByClassName('panel')[index].classList.add('is-show');
    };
});

$(function() {
    $("#dmenu > ul > li").hover(
    function() {
        $(this)
            .find("#dmenu-item")
            .stop(true)
            .slideDown(200);
    },
    function() {
        $(this)
        .find("#dmenu-item")
        .slideUp(200);
    }
    );
});

$(function() {
    $("#dmenu-close-01,#dmenu-close-02").click(
    function() {
        $(this)
        .parent()
        .parent()
        .slideUp(200);
    }
    );
});

$(function() {
    $("#spmenuSecond").hover(
        function() {
        $(this).toggleClass('is-icon-rotate')
        $(this)
            .find("#spmenu-item")
            .stop(true)
            .slideDown(200);
    },
    function() {
        $(this).toggleClass('is-icon-rotate')
        $(this)
        .find("#spmenu-item")
        .slideUp(200);
    }
    );
});
$(document).on("click", "#spmenuSecond", function() {
    $(this).toggleClass('is-icon-rotate')
    $(this)
    .find("#spmenu-item")
    .slideToggle();
});



$(function() {
	var headNav = $(".header__nav__conpact");
	$(window).on('load scroll', function () {
		if($(this).scrollTop() > 200) {
            headNav.addClass('is-fixed-nav');
		}
		else if($(this).scrollTop() < 200){
            headNav.removeClass('is-fixed-nav');
		}
	});
});

// pagetop
jQuery(function() {
    var appear = false;
    var pagetop = $('#page_top');
    $(window).on('scroll',function () {
    if ($(this).scrollTop() > 100) {
        if (appear == false) {
            appear = true;
            pagetop.stop().animate({
            'bottom': '0px'
        }, 300);
        }
    } else {
        if (appear) {
            appear = false;
            pagetop.stop().animate({
            'bottom': '-50px' //下から-50pxの位置に
          }, 300); //0.3秒かけて隠れる
        }
    }
    });
    pagetop.click(function () {
        $('body, html').animate({ scrollTop: 0 }, 800); //0.5秒かけてトップへ戻る
        return false;
    });
});

// spmenu
jQuery(function() {
    var spmenu = $('#spMenu');
    var spmenuView = $('#spMenu-view');
    spmenu.on('click',function () {
        spmenuView.toggleClass('is-menu-view');
        return false;
    });
});

jQuery(function() {
    var spmenu = $('#dmenu-close-03');
    var spmenuView = $('#spMenu-view');
    var close = $('#spMenu-view');
    spmenu.on('click',function () {
        spmenuView.toggleClass('is-menu-view');
        return false;
    });
});

jQuery(function() {
    var spmenu = $('#dmenu-close-04');
    var spmenuView = $('#spMenu-view');
    spmenu.on('click',function () {
        spmenuView.toggleClass('is-menu-view');
        return false;
    });
});

jQuery(function() {
    var spmenuBg = $('.spmenu__bg');
    var spmenuView = $('#spMenu-view');
    spmenuBg.on('click',function () {
        spmenuView.toggleClass('is-menu-view');
        return false;
    });
});


jQuery(function() {
    var popupConfirmWindow = $('#popupConfirmWindow');
    var popupConfirm = $('.confirm__popup');
    popupConfirm.on('click',function () {
        var popupConfirmName = $(this).data('name');
        var popupConfirmUrl = $(this).data('url');
        $('.confirm__link-name').text(popupConfirmName);
        $('.confirm__link >a').attr("href", popupConfirmUrl);
        popupConfirmWindow.toggleClass('is-display');
        return false;
    });
});

jQuery(function() {
    var popupConfirmWindow = $('#popupConfirmWindow');
    var confirm = $('.confirm');
    var confirmBlock = $('.confirm__block');
    var confirmClose = $('.confirm__close');
    var confirmBack = $('.confirm__back');
    var confirmLink = $('.confirm__link');

    var popupConfirmWindowContact = $('.contactForm > #popupConfirmWindow');
    var confirmContact = $('.contactForm > .confirm');
    var confirmBlockContact = $('.contactForm > .confirm > .confirm__block');
    var confirmCloseContact = $('.contactForm > .confirm > .confirm__block > .confirm__close');
    var confirmLinkContact = $('.contactForm > .confirm > .confirm__link');

    
    confirmBlock.on('click',function () {
        // console.log('click');
        return false;
    });
    confirm.on('click',function () {
        popupConfirmWindow.toggleClass('is-display');
        // console.log('bgclick');
        return false;
    });
    confirmBlock.on('click',function () {
        // console.log('click');
        return false;
    });
    confirmClose.on('click',function () {
        popupConfirmWindow.toggleClass('is-display');
        return false;
    });
    confirmBack.on('click',function () {
        popupConfirmWindow.toggleClass('is-display');
        return false;
    });
    confirmLink.on('click',function () {
        var popupConfirmUrl = $(this).find('a').attr('href');;
        popupConfirmWindow.toggleClass('is-display');
        window.open(popupConfirmUrl, '_blank');
        return false;
    });
    $('.confirm__backlink').on('click',function () {
        popupConfirmWindow.toggleClass('is-display');
        window.location.href = "/";
        return false;
    });
    // ==============

    confirmContact.on('click',function () {
        // console.log('block');
        location.reload();
        return false;
    });
    confirmCloseContact.on('click',function () {
        // console.log('close');
        location.reload();
        return false;
    });

    // ==============
});

// select box
jQuery(function() {
    /*Dropdown Menu*/
    $('.dropdown').on('click',function () {
        $(this).attr('tabindex', 1).focus();
        $(this).toggleClass('active');
        $(this).find('.dropdown-menu').slideToggle(300);
        $(this).find('span').toggleClass('tri__rotate');
    });
    $('.dropdown').on('focusout',function () {
        $(this).removeClass('active');
        $(this).find('span').removeClass('tri__rotate');
        $(this).find('.dropdown-menu').slideUp(300);
    });
    $('.dropdown .dropdown-menu li').on('click',function () {
        $(this).siblings().removeClass('checkmark');
        $(this).toggleClass('checkmark');
        $(this).parents('.dropdown').find('p').text($(this).text());
        $(this).parents('.dropdown').find('input').text($(this).text());
    });
    /*End Dropdown Menu*/
});

// agree chk
jQuery(function() {
    $('#agr').on('click' ,function(){
        var error; // エラー用の変数を定義
        if( error ) {
            // エラーが見つかった場合
        } else {
            // エラーがなかった場合
            $('.btn__01').toggleClass('non-active');
            var prop = $('.btn__01').prop('disabled');
            if (prop===true) {
                $(".btn__01").prop("disabled", false);
            } else {
                $(".btn__01").prop("disabled", true);
            }
        }
    });
});

//send mail
function sendEmail() {
    event.preventDefault();

    var purpose = $('input[name="purpose"]').text();
    var company = $('input[name="company"]').val();
    var department = $('input[name="department"]').val();
    var post = $('input[name="post"]').val();
    var name = $('input[name="name"]').val();
    var kana = $('input[name="kana"]').val();
    var tel = $('input[name="tel"]').val();
    var mail01 = $('input[name="mail01"]').val();
    var indicator = $('input[name="indicator"]:checked').val();
    var content = $('textarea[name="content"]').val();

    var mailbody = "以下の内容でお問い合わせがありました。<br>" + "<br>===============================<br><br>";
    var purpose_line =  "［お問い合わせ種別］<br>"+purpose+"<br><br>";
    var company_line =  "［お客様情報］<br>"+company + "　" + department + "　" + post + "<br>";
    var name_line =  name + "（" + kana + "）様" + "<br>";
    var tel_line =  tel + "　";
    var mail_line =  mail01 + "<br>";
    var indicator_line =  indicator + "<br><br>";
    var content_line =  "［お問い合わせ内容］<br>" + content + "<br><br>===============================";

    var message;

    mailbody = mailbody + purpose_line + company_line + name_line + tel_line + mail_line + indicator_line + content_line;

    dispLoading(message);
	Email.send({
        // Host: "smtp.elasticemail.com",
        // Password : "CC7B54F95F7ACC8B5C19B1C64DD7FC48A720",
        // Username : "muai.elastic.mail.2020@gmail.com",
        Host: "smtp.gmail.com",
        Username : "muai.elastic.mail.2020@gmail.com",
        Password : "muai_administrator",
        To : 'muai.elastic.mail.2020@gmail.com',
        From : "muai.elastic.mail.2020@gmail.com",
        Subject : "［HPからのお問い合わせ］　三菱UFJオルタナティブインベストメンツ",
        Body : mailbody,
    }).then(
        // message => alert(message),
        message => sentMessage(message)
	);
}

function dispLoading(msg){
    if( msg == undefined ){
    msg = "";
    }
    if($("#loading").length == 0){
        $("body").prepend("<div id='loading'></div>");
        $("body").toggleClass("eventnone");
    }
}

function removeLoading(){
    $("#loading").remove();
}

function sentMessage(flg) {
    $("#loading").remove();
    $("body").toggleClass("eventnone");

    if(flg=='OK'){
        $('#popupConfirmWindow').toggleClass('is-display');
        $('.confirm__link >a').attr("href", "/");
        $('.result__message').html("送信いたしました。");
    }else{
        $('#popupConfirmWindow').toggleClass('is-display');
        $('.confirm__link >a').attr("href",  "/");
        $('.result__message').html("送信に失敗しました。<br>お手数ですがトップページより<br>再度お問い合わせください。");
        // clearForm("#form");
    }
}

function clearForm (form) {
    $(form)
        .find("input, select, textarea")
        .not(":button, :submit, :reset, :hidden")
        .val("")
        .prop("checked", false)
        .prop("selected", false)
    ;
    $('.dropdown .dropdown-menu li').siblings().removeClass('checkmark');
    $('.dropdown .dropdown-menu li').toggleClass('checkmark');
    $('.dropdown .dropdown-menu li').find('p').text('選択してください');
    $('.dropdown .dropdown-menu li').find('input').text('');

    $(form).find(":radio").filter("[data-default]").prop("checked", true);
}